import {Component, Input, OnInit} from '@angular/core';
import {Vessel} from "./vessel";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'app-vessel-detail',
  templateUrl: './vessel-detail.component.html',
  styleUrls: ['./vessel-detail.component.scss']
})
export class VesselDetailComponent implements OnInit {

  @Input()
  vessel: Vessel;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {
  }

  approve(vessel) {
    vessel.enabled = true;
    this.updateUserApprovalStatus(vessel);
  }

  disapprove(vessel) {
    vessel.enabled = false;
    this.updateUserApprovalStatus(vessel);
  }

  private updateUserApprovalStatus(vessel) {
    const endpoint = 'vessels/approve';
    this.httpClient.post<any>(endpoint, vessel).subscribe((response) => {
    });
  }

}
