import {Component, OnInit, ViewChild} from '@angular/core';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';
import {AuthComponent} from "../auth/auth.component";
import {Router} from "@angular/router";
import {HttpClient} from "@angular/common/http";

@Component({
    selector: 'app-top-nav-bar',
    templateUrl: './top-nav-bar.component.html',
    styleUrls: ['./top-nav-bar.component.scss']
})
export class TopNavBarComponent implements OnInit {
    @ViewChild(AuthComponent) authComponent: AuthComponent;

    authenticated = false;
    username = '';
    isAdmin = false;
    isOther = false;

    closeResult: string;
    hidePositionList = false;
    regions = [];

    constructor(private modalService: NgbModal, private mainRoute: Router, private  httpClient: HttpClient) { }


    ngOnInit() {
        if (localStorage.getItem('token')) {
            this.authenticated = true;
            this.updateLogin();
        }
        this.loadRegions();
    }

    updateLogin() {
        this.username = localStorage.getItem('username');
        if(localStorage.getItem('isAdmin') != '') {
            this.isAdmin = true;
        } else {
            this.isAdmin = false;
        }
        const roles = localStorage.getItem('roles');

        if (roles.includes('ROLE_BROKER') || roles.includes('ROLE_SHIPPER')) {
            this.hidePositionList = true;
        } else {
            this.hidePositionList = false;
        }

        if (roles.includes('ROLE_OTHER')) {
            this.isOther = true;
        } else {
            this.isOther = false;
        }
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        });
    }

    loginSuccess() {
        this.modalService.dismissAll('login success');
        this.updateLogin();
        this.loadRegions();
    }

    getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return  `with: ${reason}`;
        }
    }


    changeAuthStatus(p) {
        this.authenticated = p.authenticated;
        this.username = p.username;
        if (this.authenticated) {
            this.loginSuccess();
        }
    }

    public logout() {
        localStorage.removeItem('token');
        localStorage.removeItem('username');
        localStorage.removeItem('isAdmin');
        this.authenticated = false;
        this.username = '';
        this.mainRoute.navigate(['welcome']);
    }

    public loadRegions() {
        const endpoint = 'regions';
        this.httpClient.get<any>(endpoint).subscribe((response) => {
            this.regions = response.result;
        });
    }

}
