import { Component, OnInit, ViewChild } from '@angular/core';
import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import * as pluginDataLabels from 'chartjs-plugin-datalabels';
import { Label, Color, BaseChartDirective,  } from 'ng2-charts';
import * as pluginAnnotations from 'chartjs-plugin-annotation';
import {FormBuilder} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-market',
  templateUrl: './market.component.html',
  styleUrls: ['./market.component.scss']
})
export class MarketComponent implements OnInit {
  public regionChartData: any;
  disabledDefaultSortOrder : Function;
  @ViewChild(BaseChartDirective) chart: BaseChartDirective;

  constructor(private fb: FormBuilder,
              private  httpClient: HttpClient) { }

  public barChartOptions = {
    animation: false,
    scaleShowVerticalLines: true,
    responsive: true,
    scales: {
      xAxes: [ {    }],
      yAxes: [{
            ticks: {    beginAtZero: true         },
            //gridLines: { color: 'rgba(255,255,255,0.1)' }
          }]
    },
    plugins: {
      datalabels: {
        anchor: 'center',
        align: 'center',
        color: '#fff'
      },
    },
    title: {
      display: false,
      text: 'Gulf Of Mexico'
    },
    tooltips:{
      enabled:true,
      callbacks: {
        label: function (tooltipItem, data) {
          const datasetLabel = data.datasets[tooltipItem.datasetIndex].label || '';
          if (datasetLabel) {
              return datasetLabel  +': '+ tooltipItem.yLabel + ' Vessels';
          }
          return ' '+tooltipItem.yLabel + ' Vessels';
        },
        title: function (tooltipItem, data) {      return '';        }
      }
    }
  };

  public barChartLabels = [];       //['Open', 'On Subject', 'Not Available'];
  public barChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [pluginDataLabels];
  public timer: any;

  public chartColors = [
    {      backgroundColor: '#4B515D'    },
    {      backgroundColor: '#ff4444'    },
    {      backgroundColor: '#ffbb33'    },
  ];


  ngOnInit() {
    this.timer = setInterval(() => {
      const endpoint = 'regions/vessel-count';
      this.httpClient.get<any>(endpoint).subscribe((response) => {
        this.regionChartData = response.result;
        this.chart.chart.update();
      });
    }, 2000);

    this.disabledDefaultSortOrder = function() { return 0;}
  }

  public chartClicked(e: any): void {
    //clearing interval on graph click
    clearInterval(this.timer);
    //console.log("clearing interval"+e);
  }
  public chartHovered(e: any): void {
    console.log(e);
  }

  public ngOnDestroy() {
    //clearing interval on page change
    clearInterval(this.timer);
  }

  //******************************************* LINE Chart ***********************


  public lineChartLabels: Label[] = ['100', '200', '300', '400', '500', '600', '700', '800'];
  public lineChartOptions: (ChartOptions & { annotation: any }) = {
    responsive: true,
    scales: {
      xAxes: [ { /*gridLines: { color: 'rgba(255,255,255,0.1)' } // to remove graph lines */   }],
      yAxes: [{
        ticks: {    beginAtZero: true         },
        //gridLines: { color: 'rgba(255,255,255,0.1)' }
      }]
    },
    plugins: {
      datalabels: {
        anchor: 'end',
        align: 'top',
        color: '#00CC00'
      }
    },
    annotation: {
      annotations: [
        {
        },
      ],
    },
  };
  public lineChartColors: Color[] = [
    { // grey
      backgroundColor: '#4B515D',
      borderColor: '#4B515D',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#4B515D',
      pointHoverBackgroundColor: '#4B515D',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    },
    { // dark grey
      backgroundColor: '#ff4444',
      borderColor: '#ff4444',
      pointBackgroundColor: 'rgba(77,83,96,1)',
      pointBorderColor: '#ff4444',
      pointHoverBackgroundColor: '#ff4444',
      pointHoverBorderColor: 'rgba(77,83,96,1)'
    },
    { // red
      backgroundColor: '#ffbb33',
      borderColor: '#ffbb33',
      pointBackgroundColor: 'rgba(148,159,177,1)',
      pointBorderColor: '#ffbb33',
      pointHoverBackgroundColor: '#ffbb33',
      pointHoverBorderColor: 'rgba(148,159,177,0.8)'
    }
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [pluginAnnotations];

  //@ViewChild(BaseChartDirective) chart: BaseChartDirective;

  public linChartData = [
    { data: [65, 59, 80, 81, 56, 55, 40], label: 'Available', borderColor: "#4B515D", fill: false },
    { data: [28, 48, 40, 19, 86, 27, 90], label: 'On Subjects', borderColor: "#ff4444", fill: false },
    { data: [180, 480, 770, 90, 600, 270, 400], label: 'Out Firm', borderColor: "#ffbb33", fill: false }
  ];

}

interface datasets {
  data: any [];
  label: string;
  borderColor: string;
  fill: boolean;
}

export interface IHash {
    id: number;
    name: string;
    dataset: datasets[];

}
