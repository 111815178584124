import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';

@Component({
  selector: 'app-collaboration',
  templateUrl: './collaboration.component.html',
  styleUrls: ['./collaboration.component.scss']
})
export class CollaborationComponent implements OnInit {
  noteForm: FormGroup;
  notes: [];

  constructor(private fb: FormBuilder,
              private  httpClient: HttpClient, private mainRoute: Router) {
      this.noteForm = this.fb.group({
          'note': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(500)]],

      })
  }

  ngOnInit() {

     this.getNotes();
  }

    getNotes() {
        const endpoint11 = 'notes/listNotes';
        this.httpClient.get<any>(endpoint11).subscribe((response) => {
            this.notes = response.result;
        });
    }
    saveNotes() {
      //alert("note saved")

        const endpoint = 'notes/addNotes';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        let params = {
            'message': this.noteForm.get('note').value,
        };

        this.httpClient.post<NotesResponse>(endpoint, params)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.getNotes();
                    this.noteForm.get('note').setValue('');
                    alert("Notes saved !!");
                }
            });

    }

}

interface NotesResponse {
    message: String;
    status: number;
    result: {
        token: string;
        username: string;
    };
}


