import {AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Subject} from "rxjs";
import {DataTableDirective} from 'angular-datatables';
import {All} from 'tslint/lib/rules/completedDocsRule';
import {ActivatedRoute, Router} from '@angular/router';
import {UpdateMyVesselsComponent} from '../update-my-vessels/update-my-vessels.component';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {FormBuilder} from '@angular/forms';

@Component({
    selector: 'app-position-list',
    templateUrl: './position-list.component.html',
    styleUrls: ['./position-list.component.scss']
})

export class PositionListComponent implements OnInit, OnDestroy, AfterViewInit {
    @ViewChild(DataTableDirective)
    dtElement: DataTableDirective;
    today: number;
    dtTrigger: Subject<Object> = new Subject();
    dtOptions: any = {};
    positions = [];
    positions1 = [];
    availablePositions = [];
    regionId: number;
    regionName: string;
    setClickedRow: Function;
    id: number;
    selectedRow : Number;
    selectedPosition : any;
    updateMe: number;
    regions : Array<any>;
    groupBy : number = 0;
    currentSelectedStatus: String;
    mailText: any;
    pageState:number = 0;
    showme : boolean = false;
    hideme : boolean = true;
    public timer: any;
    username = localStorage.getItem('username');
    userDetails: any;
    isRegionAlertSet : number = 0;
    regionAlerts : Array<any>;
    constructor(private fb: FormBuilder, private  httpClient: HttpClient,
                private route: ActivatedRoute, public dialog: MatDialog, private router: Router) {
        this.setClickedRow = function(index, data) {
            //console.log("Position Index"+ index + " Position Data : "+data);
            this.selectedRow = index;
            this.selectedPosition = data;
            //this.selectedOptions = this.selectedOptionString(data);
            this.updateMe = data.id;
        };
    }

    ngOnInit() {
        this.route.paramMap.subscribe(params => {
            this.regionId = this.route.snapshot.params.id;
            this.router.routeReuseStrategy.shouldReuseRoute = () => false;
        });
        this.hideme = this.isMobile();
        console.log("mobile-? - "+ this.hideme);

        if (!this.regionId) {
            this.dtOptions = {
                destroy: true,
                pagingType: 'full_numbers',
                pageLength: 50,
                info: true,
                ordering: true,
                responsive: true,
                searching: true,
                lengthChange: false,
                dom: 'Bfrtip',
                buttons: [
                    'pdf','copy', 'csv', 'excel',  'print' ,
                    {
                        text: this.getButtonLabel(),
                        action: this.datatableCallback(),
                        attr:  {
                            class: 'btn btn-primary',
                            id: 'exportButton'
                        }
                    },
                    {
                        text: 'Edit My Vessels',
                        attr:  {
                            id: 'editMyVessels',
                            class: 'btn btn-success'
                        },
                        action: this.editMyVesselCallback()
                    }

                ],
                order: [[ this.groupBy, 'asc' ]],
                columnDefs: [
                    { "visible": false, targets: this.groupBy}
                ],
                //order: [[ this.groupBy, 'asc' ]],
                drawCallback: function (settings) {
                    var api = this.api();
                    var rows = api.rows({page: 'current'}).nodes();
                    var last = null;
                    api.column(this.groupBy, {page: 'current'}).data().each(function (group, i) {
                        if (last !== group) {
                            $(rows).eq(i).before(
                                '<tr style="background-color:#8ac1a8;" class="group"><th colspan="11">' + group + '</th></tr>'
                            );
                            last = group;
                        }
                    });
                }
            }
        } else {
            this.dtOptions = {
                destroy: true,
                pagingType: 'full_numbers',
                pageLength: 50,
                info: true,
                ordering: true,
                responsive: true,
                searching: true,
                lengthChange: false,
                dom: 'Bfrtip',
                buttons: ['pdf','copy', 'csv', 'excel',  'print' ],
                order: [[ 1, 'asc' ]]
            };
        }
        this.today = Date.now();
        var tCount =1;
        if (this.regionId) {

           this.getRegionAlert();

            this.timer = setInterval(() => {
                this.dtOptions = {
                    destroy: true,
                    pagingType: 'full_numbers',
                    pageLength: 50,
                    info: true,
                    ordering: true,
                    responsive: true,
                    searching: true,
                    lengthChange: false,
                    dom: 'Bfrtip',
                    buttons: ['pdf','copy', 'csv', 'excel',  'print' ],
                    order: [[ 1, 'desc' ]]
                };
                const endpoint = 'regions/vessel-count/'+this.regionId;
                this.httpClient.get<any>(endpoint).subscribe((response) => {
                    this.positions1 = response.result.vesselCountDtos;
                    let k=0;
                    this.regionName = response.result.name;
                    for (let i in this.positions1) {
                        for(let j in this.positions1[i].vesselCount) {
                            this.positions[k]  = this.positions1[i].vesselCount[j];
                            if(this.positions[k].status.statusname == 'Out Firm') {
                                this.positions[k].textColor = 'bg-warning text-white';
                            } else if(this.positions[k].status.statusname == 'On Subjects') {
                                this.positions[k].textColor = 'bg-danger text-white';
                            } else {
                                this.positions[k].textColor = 'bg-dark text-white';
                            }
                            k++;
                        }
                    }
                    this.updatePositionListDatatableGrid();

                    tCount++;
                });
            }, 2000);
            if(tCount > 1) {
                // this.rerender();
            }
        } else {
            this.getPositionList();
        }
        //this.mailText = "mailto:abc@abc.com+?subject=files&body="+'<table><tr><td>kiran</td></tr><tr><td>kiran1</td></tr></table>';
    }

    private updatePositionListDatatableGrid() {
        const de = this.dtElement.dtInstance.then((instance) => {
            const order = instance.order();
            this.positions = this.positions.sort(this.getCompareFn(order[0][0], order[0][1]));
            // if(tCount == 1)
            instance.draw();
        });
    }

    getRegionAlert() {
        const endpoint1 = "position-list-alerts/region/"+this.regionId;
        this.httpClient.get<any>(endpoint1).subscribe((response) => {
            this.regionAlerts = response.result;
            if(response.result.length >= 1) {
                // console.log(response.result,response.result.length,this.regionAlerts[0].id);
                this.isRegionAlertSet = 1;
            }
        });
    }

    setUnsetRegionAlert(value) {
        let endpoint1 = 'position-list-alerts/';
        if(value == 1 ) {
            this.isRegionAlertSet = 0;
            endpoint1 += 'delete/'+this.regionAlerts[0].id
            this.httpClient.delete<RegisterResponse>(endpoint1)
                .subscribe((response) => {
                    alert("Region Alert removed successfully !");

                });
        } else {
            this.isRegionAlertSet = 1;
            endpoint1 += 'save'

            const httpOptions = {
                headers: new HttpHeaders({
                    'Content-Type': 'application/json'
                })
            };
            let params = {
                'regionId': this.regionId
            };
            this.httpClient.post<RegisterResponse>(endpoint1, params)
                .subscribe((response) => {
                    if (response.status === 200) {
                        this.getRegionAlert();
                        alert("Region Alert Added successfully !");
                    }
                });
        }
    }

    editMyVesselCallback() {
        return (e, dt, node, config) => this.routeToEditPage();
    }

    isMobile(){
        // credit to Timothy Huang for this regex test:
        // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
        console.log(navigator.userAgent);
        if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
            return true;
        }
        else{
            return false;
        }
    }

    private getCompareFn(num, order) {
        let fun = null;
        switch (num) {
            case 0: fun = (a, b) => a.region.localeCompare(b.region); break;
            case 1: fun = (a, b) => a.dtd.localeCompare(b.dtd); break;
            case 2: fun = (a, b) => a.vessel.vesselname.localeCompare(b.vessel.vesselname); break;
            case 3: fun = (a, b) => a.cwd <= b.cwd; break;
            case 4: fun = (a, b) => a.cbm <= b.cbm; break;
            case 5: fun = (a, b) => a.built <= b.built; break;
            case 6: fun = (a, b) => a.vesselDetail.imoClassified.localeCompare(b.vesselDetail.imoClassified); break;
            case 7: fun = (a, b) => a.dtd.localeCompare(b.dtd); break;
            case 8: fun = (a, b) => a.location.localeCompare(b.location); break;
            default:  fun = (a, b) => a.comment.localeCompare(b.comment);
        }
        return (a, b) => order === 'asc' ? fun(a, b) : !fun(a, b);
    }

    getPositionList() {
        const endpoint1 = 'positionlist';
        this.httpClient.get<PositionListResponse>(endpoint1).subscribe((response) => {
            this.positions = response.result;
            this.positions1 = response.result;
            this.rerender();
        });
    }

    datatableCallback() {
        return (e, dt, node, config) => this.getAvailablePositionList();
    }

    getAvailablePositionList() {
        this.showme = true;
        //this.positions1 = this.positions;
        if(this.pageState == 0) {
            document.getElementById("exportButton").innerHTML="Export Position List";
            let x = 0;
            for (let i in this.positions) {
                if (this.positions[i].status.statusname == 'Available') {
                    this.availablePositions[x] = this.positions[i];
                    x++;
                }
            }
            this.positions = this.availablePositions;
            this.pageState++;
            this.rerender();
        } else if(this.pageState == 1) {
            this.pageState++;
            this.mailMe();
        } else {
            console.log("i am in else");
            this.showme = false;
            document.getElementById("exportButton").innerHTML="Show Available Positions";
            this.pageState = 0;
            this.positions =  this.positions1;
            this.rerender();
            //window.location.reload();
        }
    }

    mailMe(){
        alert("Available Position Copied into Clipboard to Paste in Mail !!!");
        //console.log("in mail me");
        this.mailText = "mailto:?subject=Available Position List";
        document.getElementById("exportButton").innerHTML="Show All Positions";
        let copyme= document.getElementById('table-to-copied');
        //document.getElementsByTagName('colspanRow').it =10;
        let range, sel;
        // Ensure that range and selection are supported by the browsers
        if (document.createRange && window.getSelection) {
            range = document.createRange();
            sel = window.getSelection();
            // unselect any element in the page
            sel.removeAllRanges();
            try {
                range.selectNodeContents(copyme);
                sel.addRange(range);
            } catch (e) {
                range.selectNode(copyme);
                sel.addRange(range);
            }
            document.execCommand('copy');
        }
            sel.removeAllRanges();
        window.location.href = this.mailText;
    }
    reset() {
        console.log('---> Cancel Button Functionality <---');
    }
    deleteVessel(vessel: PositionList) {
        const endpoint = 'vessels/delete' + '/' + vessel.vessel.id;
        this.httpClient.get<DeleteResponse>(endpoint).subscribe((response) => {
            this.positions = this.positions.filter(item => item.id !== vessel.id);
            this.updatePositionListDatatableGrid();
        });
    }

    rerender(): void {
        try {
            this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                // Destroy the table first
                dtInstance.destroy();
                // Call the dtTrigger to rerender again
                this.dtTrigger.next();
            });
        } catch (err) {
            console.log(err);
        }
    }
    ngOnDestroy(): void {
        clearInterval(this.timer);
        // Do not forget to unsubscribe the event
        this.dtTrigger.unsubscribe();

    }
    ngAfterViewInit(): void {
        this.dtTrigger.next();
    }
    setPositionID() {
    }
    openDialog() {
        const dialogConfig = new MatDialogConfig();
        dialogConfig.disableClose = true;
        dialogConfig.autoFocus = true;
        dialogConfig.data = {};
        const dialogRef = this.dialog.open(UpdateMyVesselsComponent, dialogConfig);
        dialogRef.afterClosed().subscribe(
            data => {
                console.log("Dialog output:", data);
                if(this.updateMe != undefined && data.status != undefined) {
                    console.log("Customer Status print for Vessel id :"+ this.updateMe+" is : "+data.status);
                    const endpoint = 'positionlist/updatestatus/'+this.updateMe;
                    const httpOptions = {
                        headers: new HttpHeaders({
                            'Content-Type': 'application/json'
                        })
                    };
                    const credentials = data;
                    this.httpClient.put<any>(endpoint, data)
                        .subscribe((response) => {
                            if (response.status === 200) {
                                console.log("Position Status updated successfully : "+ response.result.status.statusname);
                                this.ngOnInit();
                            } else {
                                console.log("Failed to update position Status");
                            }
                        });
                } else {
                    console.log("Either Position id is : "+ this.updateMe+" OR Status is : "+data.statusStatus);
                }
            }
        );
    }

    private routeToEditPage() {
        this.router.navigate(['/edit-my-vessels']);
    }

    private getButtonLabel() {
        return () => (this.pageState === 0 ? 'Show Available Positions' : 'Export Position List');
    }
}

interface Item {
    regions: {
        regionname: string;
    };
    vessel: {
        id: number;
        vesselname: string;
    };
    vesselDetail: {
        imoClassified: string;
    };
}
interface PositionList {
    id: number;
    vessel: {
        id: number;
        vesselname: string;
    };
}
interface PositionListResponse {
    result: Item[];
}
interface DeleteResponse {
    result: Item;
}

interface RegisterResponse {
    message: String;
    status: number;
    result: {
        token: string;
        username: string;
    };
}
