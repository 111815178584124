import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {RegionsComponent} from '../regions/regions.component';
import {PositionListComponent} from '../position-list/position-list.component';

@Component({
  selector: 'app-update-my-vessels',
  templateUrl: './update-my-vessels.component.html',
  styleUrls: ['./update-my-vessels.component.scss']
})
export class UpdateMyVesselsComponent implements OnInit {

  updateMyVessels: FormGroup;
  position_status: Array<any>;
  position_regions: Array<any>;
  position_locations: Array<any>;
  currentSelectedStatus: string = undefined;

  constructor( private fb: FormBuilder, private  httpClient: HttpClient,
               private dialogRef: MatDialogRef<PositionListComponent>,
               @Inject(MAT_DIALOG_DATA) data)  {
    //this.currentSelectedStatus = data.currentStatus;
  }

  ngOnInit() {
    this.updateMyVessels = this.fb.group({
      'status': [, Validators.required],
      'region': [, Validators.required],
      'location': [, Validators.required],
    });

    const endpoint2 = 'positionstatus';
    this.httpClient.get<any>(endpoint2).subscribe((response) => {
      this.position_status = response.result;
      for (let i in response.result) {
        this.position_status[i].value = response.result[i].id;
        this.position_status[i].label = response.result[i].statusname;
      }
    });

    const endpoint = 'regions';
    this.httpClient.get<any>(endpoint).subscribe((response) => {
      this.position_regions = response.result;
      for (let i in response.result) {
        this.position_regions[i].value = response.result[i].id;
        this.position_regions[i].label = response.result[i].regionname;
      }

    });
  }

  save() {
    this.dialogRef.close(this.updateMyVessels.value);
  }

  close() {
    this.dialogRef.close({status: undefined});
  }
}
