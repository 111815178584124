import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {RegionsComponent} from '../regions/regions.component';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient} from '@angular/common/http';

@Component({
  selector: 'app-update-status',
  templateUrl: './update-status.component.html',
  styleUrls: ['./update-status.component.scss']
})
export class UpdateStatusComponent implements OnInit {
  updateRegionStatus: FormGroup;
  position_status: Array<any>;
  currentSelectedStatus: string = undefined;

  constructor( private fb: FormBuilder, private  httpClient: HttpClient,
               private dialogRef: MatDialogRef<RegionsComponent>,
               @Inject(MAT_DIALOG_DATA) data)  {
    this.currentSelectedStatus = data.currentStatus;
  }

  ngOnInit() {
    this.updateRegionStatus = this.fb.group({
      'status': [status, Validators.required],
    });
  console.log("status...."+this.currentSelectedStatus);
    const endpoint2 = 'positionstatus';
    this.httpClient.get<any>(endpoint2).subscribe((response) => {
      this.position_status = response.result;
      for (let i in response.result) {
        this.position_status[i].value = response.result[i].id;
        this.position_status[i].label = response.result[i].statusname;
      }
    });
  }

  save() {
    this.dialogRef.close(this.updateRegionStatus.value);
  }

  close() {
    this.dialogRef.close({status: undefined});
  }
}
