import {Component, OnInit} from '@angular/core';
import {Router} from '@angular/router';


@Component({
  selector: 'app-user-home',
  templateUrl: './user-home.component.html',
  styleUrls: ['./user-home.component.scss']
})
export class UserHomeComponent implements OnInit {

  hidePositionList = false;

  constructor(private mainRoute: Router) { }

  ngOnInit() {
    if (!localStorage.getItem('token')) {
      this.mainRoute.navigate(['welcome']);
    } else {
      const roles = localStorage.getItem('roles');
      if (roles.includes('ROLE_BROKER') || roles.includes('ROLE_SHIPPER')) {
        this.hidePositionList = true;
      }
    }
  }

}
