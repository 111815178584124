import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import { NgxTypeaheadModule } from 'ngx-typeahead';


import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HomepageComponent} from './homepage/homepage.component';
import {MaterialModule} from './material-module';
import {LeftnavComponent} from './leftnav/leftnav.component';
import {MainWindowComponent} from './main-window/main-window.component';
import {MiddleSectionComponent} from './middle-section/middle-section.component';
import {MDBBootstrapModulesPro, MDBSpinningPreloader} from 'ng-uikit-pro-standard';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './auth/token.interceptor';
import {AuthComponent} from './auth/auth.component';
import {RightPaneComponent} from './right-pane/right-pane.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {CommonModule} from '@angular/common';
import {RegisterComponent} from './register/register.component';
import {UserDetailComponent} from './user-detail/user-detail.component';
import {UserHomeComponent} from './user-home/user-home.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {environment} from '../environments/environment';
import {VesselComponent} from './vessel/vessel.component';
import {PositionListComponent} from './position-list/position-list.component';
import {EditPositionsComponent} from './edit-positions/edit-positions.component';
import {VesselListComponent} from './vessel-list/vessel-list.component';
import {VesselDetailComponent} from './vessel-detail/vessel-detail.component';
import {MarketComponent} from './market/market.component';
import {ChartsModule} from 'ng2-charts';
import {RegionsComponent} from './regions/regions.component';
import {UpdateStatusComponent} from './update-status/update-status.component';
import {AuthGuard} from './auth/auth.guard';
import {AccountComponent} from './account/account.component';
import {VesselSearchComponent} from './vessel-search/vessel-search.component';
import {VoyageEstimatorComponent} from './voyage-estimator/voyage-estimator.component';
import {DataTablesModule} from 'angular-datatables';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {TopNavBarComponent} from "./top-nav-bar/top-nav-bar.component";
import { VesselPositionComponent } from './vessel-position/vessel-position.component';
import { ChangeLogComponent } from './change-log/change-log.component';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { UpdateMyVesselsComponent } from './update-my-vessels/update-my-vessels.component';
import { CollaborationComponent } from './collaboration/collaboration.component';

@NgModule({
  declarations: [
    AppComponent,
    HomepageComponent,
    LeftnavComponent,
    MainWindowComponent,
    MiddleSectionComponent,
    RightPaneComponent,
    AuthComponent,
    RegisterComponent,
    UserDetailComponent,
    UserHomeComponent,
    WelcomeComponent,
    VesselComponent,
    PositionListComponent,
    EditPositionsComponent,
    VesselListComponent,
    VesselDetailComponent,
    MarketComponent,
    RegionsComponent,
    UpdateStatusComponent,
    AccountComponent,
    VesselSearchComponent,
    TopNavBarComponent,
    VoyageEstimatorComponent,
    VesselPositionComponent,
    ChangeLogComponent,
    UpdateMyVesselsComponent,
    CollaborationComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    MDBBootstrapModulesPro.forRoot(),
    CommonModule,
    ReactiveFormsModule,
    HttpClientModule,
    FormsModule,
    ChartsModule,
    DataTablesModule,
    NgbModule,
    AngularFontAwesomeModule,
    NgxTypeaheadModule
  ],
  entryComponents: [UpdateStatusComponent, UpdateMyVesselsComponent],

  providers: [
    MDBSpinningPreloader,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true
    },
    {
      provide: 'BASE_API_URL',
      useValue: environment.apiUrl
    },
    AuthGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
