import { Component, OnInit } from '@angular/core';
import {Router} from '@angular/router';

@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomepageComponent implements OnInit {

  user = '';

  constructor(private mainRoute: Router) { }

  ngOnInit() {
    this.user = localStorage.getItem('username');
  }

  redirectToHome() {
    if (localStorage.getItem('username') != '') {

      if (localStorage.getItem('isAdmin')) {
        this.mainRoute.navigate(['home']);
      } else {
        this.mainRoute.navigate(['user-home']);
      }
    }
  }



}
