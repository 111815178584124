export class Vessel {
    id: number;
    vesselname: string;
    regionid: number;
    imo: number;
    opendate: string;
    openport: string;
    vesseltypeid: number;
    enabled: boolean;
}
