import {Component, OnInit} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Vessel} from "../vessel-detail/vessel";

interface Vesselresponse {
  status: string;
  message: string;
  result: Vessel[];
}

@Component({
  selector: 'app-vessel-list',
  templateUrl: './vessel-list.component.html',
  styleUrls: ['./vessel-list.component.scss']
})
export class VesselListComponent implements OnInit {

  vessels: Vessel[] = [];


  constructor(private  httpClient:  HttpClient) { }

  ngOnInit() {
    const endpoint = 'vessels';
    this.httpClient.get<Vesselresponse>(endpoint).subscribe((response) => {
      this.vessels = response.result;
    });
  }

}
