import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-middle-section',
  templateUrl: './middle-section.component.html',
  styleUrls: ['./middle-section.component.scss']
})
export class MiddleSectionComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
