import {Component, OnInit} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ErrorStateMatcher} from '@angular/material';
import {Router} from '@angular/router';

@Component({
    selector: 'app-register',
    templateUrl: './register.component.html',
    styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

    registerForm: FormGroup;
    isSubmitting = false;
    authType: string = null;
    registerSuccess = false;
    companies: Array<any>;
    industryTypes: Array<any>;
    newCompanyNumber: string;
    lastCompanyNumber: string;
    newCompanyId: number;
    errorMatcher = new CrossFieldErrorMatcher();

    constructor(private fb: FormBuilder,
                private  httpClient: HttpClient, private mainRoute: Router) {
        this.registerForm = this.fb.group({
            'username': ['', {
                updateOn: 'blur',
                validators: Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(20)]),
                asyncValidators: [validateEmail(httpClient)]
            }],
            'password': ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]],
            'verifyPassword': ['', Validators.required],
            'firstname': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]],
            'lastname': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]],
            'title': [''],
            'company': ['', Validators.required],
            'newcompany': [''],
            'address1': ['', Validators.required],
            'address2': [''],
            'city': ['', Validators.required],
            'postalCode': ['', Validators.required],
            'phone': ['', [Validators.required, Validators.minLength(10), Validators.maxLength(13)]],
            'mobile': [''],
            'industryType': ['', Validators.required],
            'email': ['', {
                updateOn: 'blur',
                validators: Validators.compose([
                    Validators.required,
                    Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
                asyncValidators: [validateEmail(httpClient)]
            }
            ]
        }, {
          validator: [this.passwordValidator,this.newCompanyValidator]
        });
    }

    ngOnInit() {
        this.authType = 'register';
        this.companies = [
            {value: '0', label: 'New Company'}
        ];

        const endpoint11 = 'companies';
        this.httpClient.get<any>(endpoint11).subscribe((response) => {
            this.companies = response.result;
            let j;
            for (let i in response.result) {
                if(response.result[i].name == 'New Company') {
                    console.log('new company'+ response.result[i].id);
                    this.newCompanyNumber = response.result[i].id;
                }
                this.companies[i].value = response.result[i].id;
                this.companies[i].label = response.result[i].name;
                this.lastCompanyNumber = i;
                console.log("Last company id "+ this.lastCompanyNumber+1 )
            }

        });

        const endpoint1 = 'roles';
        this.httpClient.get<any>(endpoint1).subscribe((response) => {
            this.industryTypes = response.result;
            for (let i in response.result) {
                this.industryTypes[i].value = response.result[i].id;
                this.industryTypes[i].label = response.result[i].rolename.toString().replace(/ROLE|_/g, ' ');
            }
        });

    }

    register() {
        this.isSubmitting = true;
        const endpoint = 'users/signup';
        const endpoint1 = 'companies/add';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };

        if(this.registerForm.get('company').value == 1 ) {
            let params1 = {
                'id': parseInt(this.lastCompanyNumber) + 1,
                'name': this.registerForm.get('newcompany').value.toString().trim(),
            };

            this.httpClient.post<RegisterResponse>(endpoint1, params1)
                .subscribe((response) => {
                    if (response.status === 200) {
                      //this.newCompanyId = response
                    }
                });

        }
        this.newCompanyId = this.registerForm.get('company').value == 1 ? parseInt(this.lastCompanyNumber)+1 :  this.registerForm.get('company').value;
        console.log("New company id "+ this.newCompanyId);
        //const credentials = this.registerForm.value;
        let params = {
            'username': this.registerForm.get('username').value,
            'password': this.registerForm.get('password').value,
            'firstname': this.registerForm.get('firstname').value,
            'lastname': this.registerForm.get('lastname').value,
            'title': this.registerForm.get('title').value,
            'company': this.newCompanyId,
            'address1': this.registerForm.get('address1').value,
            'address2': this.registerForm.get('address2').value,
            'city': this.registerForm.get('city').value,
            'postalCode': this.registerForm.get('postalCode').value,
            'phone': this.registerForm.get('phone').value,
            'mobile': this.registerForm.get('mobile').value,
            'industryType': this.registerForm.get('industryType').value,
            'email': this.registerForm.get('email').value
        };
        this.httpClient.post<RegisterResponse>(endpoint, params)
            .subscribe((response) => {
                if (response.status === 200) {
                    alert("User has been registered successfully !\nYour will be notified once account is approved by an Administrator !\nThank You");
                    this.mainRoute.navigate(['welcome']);
                    this.registerSuccess = true;
                }
            });
        this.isSubmitting = false;
    }

    passwordValidator(form: FormGroup) {
      const condition = form.get('password').value !== form.get('verifyPassword').value;
      return condition ? { passwordsDoNotMatch: true} : null;
    }

    newCompanyValidator(form:FormGroup) {
        const condition1 = form.get('company').value == 1 && form.get('newcompany').value.toString().trim().length == 0;
        return condition1 ? { enterNewCompany: true} : null;
    }
}

interface RegisterResponse {
    message: String;
    status: number;
    result: {
        token: string;
        username: string;
    };
}

interface FetchuserResponse {
    status: number;
    result: string;
}


export function validateEmail(httpClient: HttpClient): AsyncValidatorFn {
    return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
        const endpoint = 'users/exists/' + control.value;
        return new Promise(function (resolve, reject) {
            httpClient.get<FetchuserResponse>(endpoint)
                .subscribe((response) => {
                    if (response.result !== '') {
                        resolve({'userExists': true});
                    } else {
                        resolve(null);
                    }
                });
        });
    };
}

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        return control.dirty && form.invalid;
    }
}
