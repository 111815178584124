import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Subject} from "rxjs";
import {DataTableDirective} from "angular-datatables";
import {All} from 'tslint/lib/rules/completedDocsRule';


@Component({
  selector: 'app-change-log',
  templateUrl: './change-log.component.html',
  styleUrls: ['./change-log.component.scss']
})
export class ChangeLogComponent implements OnInit, OnDestroy, AfterViewInit  {

  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  today: number;
  dtTrigger: Subject<Object> = new Subject();
  dtOptions: any = {};
  positions = [];
  changeInfoList : any;
    public timer: any;
  constructor(private  httpClient: HttpClient) {  }

  ngOnInit() {
    this.dtOptions = {
      destroy: true,
      pagingType: 'full_numbers',
      pageLength: 50,
      info: true,
      ordering: true,
      responsive: true,
      searching: true,
      lengthChange: false
    };


      this.getChangeLogData();
      this.timer = setInterval(() => {
            this.getChangeLogData();
          /*this.httpClient.get<ChangeInfoListResponse>(endpoint1).subscribe((response) => {
              this.changeInfoList = response;
              this.rerender();
          });*/

      }, 60000);

  }


    private getChangeLogData() {
        const endpoint1 = 'change-info';
        this.httpClient.get<ChangeInfoListResponse>(endpoint1).subscribe((response) => {
            this.changeInfoList = response;
            this.rerender();
        });
    }

  reset() {
    console.log('---> Cancel Button Functionality <---');
  }


  rerender(): void {
    this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
      // Destroy the table first
      dtInstance.destroy();
      // Call the dtTrigger to rerender again
      this.dtTrigger.next();
    });
  }
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
    clearInterval(this.timer);
  }

  ngAfterViewInit(): void {
    this.dtTrigger.next();
  }


}

interface Item {
  regions: {
    regionname: string;
  };
  vessel: {
    id: number;
    vesselname: string;
  };
}


interface ChangeInfoListResponse {
  result: Item[];
}

interface DeleteResponse {
  result: Item;
}
