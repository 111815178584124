import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-voyage-estimator',
  templateUrl: './voyage-estimator.component.html',
  styleUrls: ['./voyage-estimator.component.scss']
})
export class VoyageEstimatorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
