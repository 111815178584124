import {Component, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {ActivatedRoute} from '@angular/router';
import {MatDialog, MatDialogConfig} from '@angular/material';
import {UpdateStatusComponent} from '../update-status/update-status.component';

@Component({
  selector: 'app-regions',
  templateUrl: './regions.component.html',
  styleUrls: ['./regions.component.scss']
})

export class RegionsComponent implements OnInit {
  regionTitle: string;
  regionData : any[];
  openStatusData : Array<any>;
  onSubjectStatusData : Array<any>;
  notAvailableStatusData : Array<any>;
  selectedOptions: string = "Select Vessel to see details";
  setClickedRow: Function;
  id: number;
  selectedRow : Number;
  selectedPosition : any;
  updateMe: number;
  currentSelectedStatus: string;
  timer: any;

  constructor(private fb: FormBuilder, private  httpClient: HttpClient,
              private route: ActivatedRoute, public dialog: MatDialog) {

    this.setClickedRow = function(index, data) {
      this.selectedRow = index;
      this.selectedPosition = data;
      this.selectedOptions = this.selectedOptionString(data);
      this.updateMe = data.id;
    };
  }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = this.route.snapshot.params.id;
      this.getRegionData();
    });
  }

    private getRegionData() {
        const endpoint = 'regions/vessel-count/' + this.id;
        this.httpClient.get<any>(endpoint).subscribe((response) => {
            this.regionTitle = response.result.name;
            this.regionData = response.result.vesselCountDtos;
            this.onSubjectStatusData = [];
            this.openStatusData = [];
            this.notAvailableStatusData = [];
            for (let i in this.regionData) {
                if (this.regionData[i].status == 'On Subjects') {
                    this.onSubjectStatusData = this.regionData[i].vesselCount;
                }
                if (this.regionData[i].status == 'Available') {
                    this.openStatusData = this.regionData[i].vesselCount;
                }
                if (this.regionData[i].status == 'Out Firm') {
                    this.notAvailableStatusData = this.regionData[i].vesselCount;
                }
            }
        });
       // console.log("On subject : "+  this.onSubjectStatusData);
        //console.log("On Open : "+  this.openStatusData);

    }

    openDialog() {

      const dialogConfig = new MatDialogConfig();
      dialogConfig.disableClose = true;
      dialogConfig.autoFocus = true;
      dialogConfig.data = {
          currentStatus: this.currentSelectedStatus,
         // position_status: this.position_status
      }
      const dialogRef = this.dialog.open(UpdateStatusComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(
          data => {
              console.log("Dialog output:", data);
              if(this.updateMe != undefined && data.status != undefined) {
                  console.log("Customer Status print for Vessel id :"+ this.updateMe+" is : "+data.status);
                  const endpoint = 'positionlist/updatestatus/'+this.updateMe;
                  const httpOptions = {
                      headers: new HttpHeaders({
                          'Content-Type': 'application/json'
                      })
                  };
                  const credentials = data;
                  this.httpClient.put<any>(endpoint, data)
                      .subscribe((response) => {
                          if (response.status === 200) {
                              console.log("Position Status updated successfully : "+ response.result.status.statusname);
                              this.getRegionData();
                              this.selectedOptions = this.selectedOptionString(response.result);//.vessel.vesselname+', '+response.result.status.statusname+', DWT '+response.result.dwt+', Owner '+response.result.vessel.owner.username;
                              this.selectedPosition = response.result;
                          } else {
                              console.log("Failed to update position Status");
                          }
                      });
              } else {
                  console.log("Either Position id is : "+ this.updateMe+" OR Status is : "+data.statusStatus);
              }
          }
      );
  }

   selectedOptionString (data) {
      this.currentSelectedStatus = data.status.statusname;
      return  data.vessel.vesselname + ', ' + data.status.statusname
          + ', DWT ' + data.dwt
          + ', Owner ' + data.vessel.owner.username
          + ' build year: ' + data.vesselDetail.buildYear
          + ' Vessel Type: ' + data.vesselDetail.vesselType
          + ' length: ' + data.vesselDetail.length
          + ' breadth: ' + data.vesselDetail.breadth
          + ' depth: ' + data.vesselDetail.depth
          ;
    }
    onNgModelChange(event){
        console.log('on ng model change', event);
    }

    public ngOnDestroy() {
        //clearing timer  on page change
        clearInterval(this.timer);
    }
}
