import {Component, EventEmitter, OnInit, Output, QueryList, ViewChildren} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {SBItemComponent} from 'ng-uikit-pro-standard';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  isSubmitting: Boolean = false;
  authForm: FormGroup;
  authenticated: Boolean = false;
  loginVisible = true;
  username = '';
  loginFailed = false;

  @Output() authChanged = new EventEmitter();


  @ViewChildren(SBItemComponent) collapses: QueryList<SBItemComponent>[];


  constructor(private route: ActivatedRoute,
              private fb: FormBuilder,
              private  httpClient: HttpClient,
              private mainRoute: Router) {
    this.authForm = this.fb.group({
      'email': ['', Validators.required],
      'password': ['', Validators.required]
    });
  }

  ngOnInit() {
    if (localStorage.getItem('token')) {
      this.loginVisible = false;
      this.authenticated = true;
      this.username = localStorage.getItem('username');
      this.updateValues();
    }
  }

  submitForm() {
    this.isSubmitting = true;
    this.doLogin();
    this.isSubmitting = false;
  }

  private doLogin() {
    const endpoint = 'token/generate-token';
    localStorage.removeItem('token');
    localStorage.removeItem('username');
    localStorage.removeItem('isAdmin');
    localStorage.removeItem('roles');
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };
    const credentials = this.authForm.value;
    this.httpClient.post<LoginResponse>(endpoint, {
      username: credentials.email,
      password: credentials.password
    }).subscribe((response) => {
      console.log(response);
      if (response.message == 'success') {
        this.authenticated = true;
        this.loginFailed=false;
        this.username = response.result.username;
        localStorage.setItem('token', response.result.token);
        localStorage.setItem('username', response.result.username);
        localStorage.setItem('isAdmin', response.result.isAdmin);
        if(response.result.roles === null) {
          localStorage.setItem('roles', '');
        } else {
          localStorage.setItem('roles', response.result.roles.toString());
        }
        if (response.result.isAdmin !== '') {
          this.mainRoute.navigate(['home']);
        } else {
          this.mainRoute.navigate(['user-home']);
        }
        this.loginVisible = false;
        this.updateValues();
      }

    },
        error1 => {
          if(error1.status == 401){
            this.loginFailed=true;
          }
        });
  }

  updateValues() {
    this.authChanged.emit({ authenticated: this.authenticated, username: this.username });
  }

}

interface LoginResponse {
  message: String;
  status: number;
  result: {
    token: string;
    username: string;
    isAdmin: string;
    roles: Array<String>;
  };
}
