import {Component, Input, OnInit} from '@angular/core';
import { User } from './user';
import {HttpClient, HttpHeaders, HttpErrorResponse} from '@angular/common/http';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss']
})
export class UserDetailComponent implements OnInit {

  @Input()
  user: User;

  constructor(private httpClient: HttpClient) { }

  ngOnInit() {

  }

  approve(user) {
    user.enabled = true;
    this.updateUserApprovalStatus(user);
  }

  disapprove(user) {
    user.enabled = false;
    this.updateUserApprovalStatus(user);
  }

  private updateUserApprovalStatus(user) {
    const endpoint = 'users/approve';
    this.httpClient.post<ApproveUserResponse>(endpoint, user).subscribe((response) => {
    });
  }

}


interface ApproveUserResponse {
  status: number;
  message: string;
  result: User;
}
