import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';

@Component({
  selector: 'app-vessel-search',
  templateUrl: './vessel-search.component.html',
  styleUrls: ['./vessel-search.component.scss']
})
export class VesselSearchComponent implements OnInit {

  url = 'vessels/search-by-name';
  search = '';
  params = {
    name: this.search
  };
  vesselSearch: FormGroup;
  url1: string;
  urlSafe: SafeResourceUrl;

    imo1: string = null;
    vesselName: string = null;
    dwt: string = null;
    length: string = null;
    breadth: string = null;
    depth: string = null;
    gwt: string = null;
    callSign: string = null;
    classSociety: string = null;
    imoClassified: string = null;
    builder: string = null;
    flag: string = null;
    operator: string = null;
    owner: string = null;
    show: boolean = false;
  constructor(private fb: FormBuilder, public sanitizer: DomSanitizer,private hostElement: ElementRef) { }

  ngOnInit() {

    this.vesselSearch = this.fb.group({
      imo: ['', Validators.required],
    });



  }


  //onKey(event: any) { // without type info
  //   this.imoEntered = event.target.value;
  //}

  setURL(imo1) {
    //console.log("kiran here"+$event.target.value);
    const iframe = this.hostElement.nativeElement.querySelector('iframe');
    //this.url1="http://api.shipfinder.com/apicall/location?k=f471af8068cc4fa3a4638ce860338449&kw="+imo1+"&tip=1&track=0";
    this.url1="https://www.vesselfinder.com/aismap?zoom=8&amp;width=100%&amp;height=400&amp;names=true&amp;imo="+imo1+"&amp;track=true&amp;fleet=false&amp;fleet_name=false&amp;fleet_hide_old_positions=false&amp;clicktoact=false&amp;store_pos=true&amp;ra=https%3A%2F%2Fwww.vesselfinder.com%2Fembed";
    //console.log(this.url);
    iframe.src = this.url1;
  }

  populateVessel (result, flag) {
    console.log("i m in populate");
    let vsdetail = result.vesselName + " | "+ result.imo;
      this.vesselSearch.get('imo').setValue("");
   // this.vesselSearch.get('imo').setValue(vsdetail);
      this.imo1 = result.imo;
      this.vesselName = result.vesselName;
      this.dwt = result.dwt;
      this.length = result.length;
      this.breadth = result.breadth;
      this.depth = result.depth;
      this.gwt = result.gwt;
      this.callSign = result.callSign;
      this.classSociety = result.classSociety;
      this.imoClassified = result.imoClassified;
      this.builder = result.builder;
      this.flag = result.flag;
      this.operator = result.operator;
      this.owner = result.owner;
      //console.log(this.vesselName +"|"+this.callSign +"|"+this.owner);
      //this.setURL(result.imo);
    //this.addVesselForm.get('vesselType').setValue(result.vesselType);
  }

  HideShowSearchOption(arg) {
      console.log("Action :"+arg);
      if(arg =='open') {
          this.show=true;
      } else {
          this.show=false;
      }
  }
}



