import {Component, ElementRef, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer, SafeResourceUrl} from '@angular/platform-browser';
import {isNullOrUndefined} from "util";
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-vessel-position',
  templateUrl: './vessel-position.component.html',
  styleUrls: ['./vessel-position.component.scss']
})
export class VesselPositionComponent implements OnInit {
  url = 'vessels/search-by-name';
  search = '';
  params = {
    name: this.search
  };
  vesselPosition: FormGroup;
  url1: string;
  urlSafe: SafeResourceUrl;
  urlimo : string;

  constructor(private fb: FormBuilder, private route: ActivatedRoute, public sanitizer: DomSanitizer,private hostElement: ElementRef) { }

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.urlimo = this.route.snapshot.params.imo;
      console.log("URLIMO :"+this.urlimo);
    });


    this.vesselPosition = this.fb.group({
      imo: [this.urlimo, Validators.required],
    });

    if(this.urlimo) {
      this.setURL(this.urlimo);
      //this.vesselPosition.get("imo").setValue(this.urlimo);
    }

  }


  //onKey(event: any) { // without type info
 //   this.imoEntered = event.target.value;
  //}

  setURL(imo1) {
    //console.log("kiran here"+$event.target.value);
    const iframe = this.hostElement.nativeElement.querySelector('iframe');
    //this.url1="http://api.shipfinder.com/apicall/location?k=f471af8068cc4fa3a4638ce860338449&kw="+imo1+"&tip=1&track=0";
    this.url1="https://www.vesselfinder.com/aismap?zoom=8&amp;width=100%&amp;height=400&amp;names=true&amp;imo="+imo1+"&amp;track=true&amp;fleet=false&amp;fleet_name=false&amp;fleet_hide_old_positions=false&amp;clicktoact=false&amp;store_pos=true&amp;ra=https%3A%2F%2Fwww.vesselfinder.com%2Fembed";
    //console.log(this.url);
    iframe.src = this.url1;
  }

  populateVessel (result) {
    //console.log("i m in populate");
    this.vesselPosition.get('imo').setValue(result.imo);
    this.setURL(result.imo);
    //this.addVesselForm.get('vesselType').setValue(result.vesselType);
  }

  setIMO(){
    if(this.urlimo) {
      this.vesselPosition.get('imo').setValue(this.urlimo);
      this.urlimo=null;
    }
  }
}

