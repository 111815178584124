import {Component, OnInit} from "@angular/core";
import {FormBuilder, FormGroup, Validators} from "@angular/forms";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Router} from '@angular/router';


@Component({
    selector: 'app-edit-positions',
    templateUrl: './edit-positions.component.html',
    styleUrls: ['./edit-positions.component.scss']
})
export class EditPositionsComponent implements OnInit {

    editPositionForm: FormGroup;
    isSubmitting = false;
    authType: string = null;
    positionUpdateSuccess: boolean = false;
    regions: Array<any>;
    position_status: Array<any>;
    myvessels: Array<any>;

    constructor(private fb: FormBuilder,
                private  httpClient: HttpClient, private mainRoute: Router) {
        this.editPositionForm = this.fb.group({
            'myvessels': ['', {
                updateOn: 'blur',
                validators: Validators.compose([Validators.required]),
            }],
            'region': ['', Validators.required],
            'imo': ['', [Validators.required, Validators.minLength(7), Validators.maxLength(7)]],
            'built': ['', Validators.required],
            'ice': [,],
            'status': ['', Validators.required],
            'location': ['', Validators.required],
            'cbm': ['', Validators.required],
            'dtd': ['', Validators.required],
            'dwt': ['', Validators.required],
            'opendate': ['', Validators.required],
            'lastthreecargoes': ['', Validators.required],
            'comment': ['', Validators.required],
        });
    }

    ngOnInit() {
        this.authType = 'editPositionList';

        const endpoint = 'vessels/getMyVessels';
        this.httpClient.get<any>(endpoint).subscribe((response) => {
            this.myvessels = response.result;
            for (let i in response.result) {
                this.myvessels[i].value = response.result[i].id;
                this.myvessels[i].label = response.result[i].vesselname;
            }

        });

        const endpoint1 = 'regions';
        this.httpClient.get<any>(endpoint1).subscribe((response) => {
            this.regions = response.result;
            for (let i in response.result) {
                this.regions[i].value = response.result[i].id;
                this.regions[i].label = response.result[i].regionname;
            }

        });

        const endpoint2 = 'positionstatus';
        this.httpClient.get<any>(endpoint2).subscribe((response) => {
            this.position_status = response.result;
            for (let i in response.result) {
                this.position_status[i].value = response.result[i].id;
                this.position_status[i].label = response.result[i].statusname;
            }

        });
    }

    editPositions() {
        console.log(this.editPositionForm.value);

        this.isSubmitting = true;
        const endpoint = 'positionlist/addPositions';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        this.editPositionForm.controls['ice'].setValue(localStorage.getItem('username'));
        const credentials = this.editPositionForm.value;
        this.httpClient.post<any>(endpoint, credentials)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.positionUpdateSuccess = false;
                    alert("Selected vessel has been updated successfully ! ");
                    this.mainRoute.navigate(['edit-my-vessels']);
                }
            });
        this.isSubmitting = false;

    }

    positionSelect(event: any) {
        console.log(event);
        const vessel = event;
        const endpoint = 'positionlist/get/' + vessel.id;
        this.httpClient.get<any>(endpoint).subscribe((response) => {
            if(response.result) {
                const pos = response.result;
                (this.editPositionForm.controls['imo']).setValue(pos.vesselDetail.imo);
                (this.editPositionForm.controls['built']).setValue(pos.built);
                (this.editPositionForm.controls['ice']).setValue(pos.ice);
                (this.editPositionForm.controls['status']).setValue(pos.status.id);
                (this.editPositionForm.controls['region']).setValue(pos.regions.id);
                (this.editPositionForm.controls['location']).setValue(pos.location);
                (this.editPositionForm.controls['dwt']).setValue(pos.dwt);
                (this.editPositionForm.controls['dtd']).setValue(pos.dtd);
                (this.editPositionForm.controls['cbm']).setValue(pos.vessel.cbm);
                (this.editPositionForm.controls['lastthreecargoes']).setValue(pos.vessel.lastthreecargoes);
                (this.editPositionForm.controls['opendate']).setValue(pos.vessel.opendate);
                (this.editPositionForm.controls['comment']).setValue(pos.vessel.comment);
            } else {
                (this.editPositionForm.controls['imo']).setValue(vessel.imo);
                (this.editPositionForm.controls['built']).setValue(vessel.vesselDetail.buildYear);
                (this.editPositionForm.controls['ice']).setValue('');
                (this.editPositionForm.controls['status']).setValue('');
                (this.editPositionForm.controls['region']).setValue(vessel.regionid);
                (this.editPositionForm.controls['location']).setValue('');
                (this.editPositionForm.controls['dwt']).setValue(vessel.vesselDetail.dwt);
                (this.editPositionForm.controls['lastthreecargoes']).setValue(vessel.lastthreecargoes);
                (this.editPositionForm.controls['opendate']).setValue(vessel.opendate);
                (this.editPositionForm.controls['comment']).setValue(vessel.comment);
                (this.editPositionForm.controls['cbm']).setValue(vessel.cbm);

            }
        });

    }
}
