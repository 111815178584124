import {Component, Inject, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {isNullOrUndefined} from "util";
import {Router} from '@angular/router';


@Component({
    selector: 'app-vessel',
    templateUrl: './vessel.component.html',
    styleUrls: ['./vessel.component.scss']
})
export class VesselComponent implements OnInit {

    url = 'vessels/search-by-name';
    search = '';
    params = {
        name: this.search
    };

    today = new Date();
    addVesselForm: FormGroup;
    isSubmitting = false;
    authType: string = null;
    addVesselSuccess = false;
    regions: Array<any>;
    vessel_types: Array<any>;


    constructor(private fb: FormBuilder,
                private  httpClient: HttpClient, private mainRoute: Router,
                @Inject('BASE_API_URL') private baseUrl: string) {
        this.addVesselForm = this.fb.group({
            'vesselname': ['', {
                updateOn: 'blur',
                validators: Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(20)]),
            }],
            'regionid': [''],
            'imo': ['', [Validators.required, Validators.minLength(7), Validators.maxLength(7)]],
            'opendate': [''],
            'openport': [''],
            'vesseltypeid': [''],
            'lastthreecargoes': [''],
            'cbm': [''],
            'comment': ['']
        });
        this.addVesselForm.get('imo').valueChanges.subscribe(val => {
            this.fetchVesselDetails(val);
        });
    }

    ngOnInit() {
        this.authType = 'addVessel';
        const endpoint = 'regions';
        this.httpClient.get<any>(endpoint).subscribe((response) => {
            this.regions = response.result;
            for (let i in response.result) {
                this.regions[i].value = response.result[i].id;
                this.regions[i].label = response.result[i].regionname;
            }

        });
        const endpoint1 = 'vesseltypes';
        this.httpClient.get<any>(endpoint1).subscribe((response) => {
            this.vessel_types = response.result;
            for (let i in response.result) {
                this.vessel_types[i].value = response.result[i].id;
                this.vessel_types[i].label = response.result[i].vesseltype;
            }

        });
}

    addVessel() {
        console.log(this.addVesselForm.value);

        this.isSubmitting = true;
        const endpoint = 'vessels/add';
        const httpOptions = {
            headers: new HttpHeaders({
                'Content-Type': 'application/json'
            })
        };
        const credentials = this.addVesselForm.value;
        this.httpClient.post<any>(endpoint, credentials)
            .subscribe((response) => {
                if (response.status === 200) {
                    this.addVesselSuccess = true;
                    alert("Vessel has been added successfully !");
                    this.mainRoute.navigate(['edit-my-vessels']);
                }
            });
        this.isSubmitting = false;
    }


    fetchVesselDetails(imo) {
        console.log("i am in fetchvesse");
        if (isNullOrUndefined(imo)) {
            return;
        }
        const endpoint = 'vessels/vessel-detail/' + imo;
        this.httpClient.get<any>(endpoint).subscribe((response) => {
            const vesselDetail = response.result;
            (this.addVesselForm.controls['vesselname']).setValue(vesselDetail.vesselName);
            (this.addVesselForm.controls['cbm']).setValue(vesselDetail.cubic);
        });
    }

    populateVessel (result) {
        this.addVesselForm.get('imo').setValue(result.imo);
        this.addVesselForm.get('vesselType').setValue(result.vesselType);
    }
}
