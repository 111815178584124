import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthModule} from './auth/auth.module';
import {RegisterComponent} from './register/register.component';
import {MainWindowComponent} from './main-window/main-window.component';
import {UserHomeComponent} from './user-home/user-home.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {PositionListComponent} from './position-list/position-list.component';
import {VesselComponent} from './vessel/vessel.component';
import {EditPositionsComponent} from './edit-positions/edit-positions.component';
import {VesselListComponent} from './vessel-list/vessel-list.component';
import {MarketComponent} from './market/market.component';
import {RegionsComponent} from './regions/regions.component';
import {AuthGuard} from './auth/auth.guard';
import {VesselSearchComponent} from './vessel-search/vessel-search.component';
import {AccountComponent} from './account/account.component';
import {VoyageEstimatorComponent} from './voyage-estimator/voyage-estimator.component';
import {VesselPositionComponent} from './vessel-position/vessel-position.component';
import {ChangeLogComponent} from './change-log/change-log.component';
import {CollaborationComponent} from './collaboration/collaboration.component';

const routes: Routes = [
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'home',
    component: MainWindowComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'user-home',
    component: UserHomeComponent,
    canActivate: [AuthGuard]

  },
  {
    path: 'welcome',
    component: WelcomeComponent,
  },
  {
    path: 'my-vessels',
    component: PositionListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'position-list/:id',
    component: PositionListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vessel',
    component: VesselComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'edit-my-vessels',
    component: EditPositionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'list-vessels',
    component: VesselListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'market',
    component: MarketComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'regions/:id',
    component: RegionsComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vessel-search',
    component: VesselSearchComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vessel-position',
    component: VesselPositionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'vessel-position/:imo',
    component: VesselPositionComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'voyage-estimator',
    component: VoyageEstimatorComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'collaboration',
    component: CollaborationComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'change-log',
    component: ChangeLogComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    component: WelcomeComponent,
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes),
    AuthModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
