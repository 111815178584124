import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormBuilder,
  FormControl,
  FormGroup,
  FormGroupDirective,
  NgForm,
  ValidationErrors,
  Validators
} from '@angular/forms';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {ErrorStateMatcher} from '@angular/material';
import {Router} from '@angular/router'
import {User} from '../user-detail/user';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {

  @Input()
  user: User;

  registerForm: FormGroup;
  username: string;
  isSubmitting = false;
  authType: string = null;
  registerSuccess = false;
  userDetails: any;

  currentEmail: null;

  constructor(private fb: FormBuilder, private  httpClient: HttpClient, private mainRoute: Router) {

    this.registerForm = this.fb.group({
      'title': [''],
      'firstname': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]],
      'lastname': ['', [Validators.required, Validators.minLength(1), Validators.maxLength(45)]],
      'mobile': [''],
      'email': ['', {
        updateOn: 'blur',
        validators: Validators.compose([
          Validators.required,
          Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')]),
        asyncValidators: [validateEmail(httpClient, this)]
      }
      ],
      'mobileAlerts': [''],
      'emailAlerts': ['']

    });

  }

  ngOnInit() {

    this.authType = 'register';
    this.username = localStorage.getItem('username');

    const endpoint1 = "users/getbyname/"+this.username;
    this.httpClient.get<any>(endpoint1).subscribe((response) => {
      this.userDetails = response.result;
      this.currentEmail = this.userDetails.email;
      //this.userDetails.textAlert = 0;
      //this.userDetails.emailAlert = 1;
      console.log(response.result);
    });

  }

  register1() {

    this.isSubmitting = true;
    const endpoint = 'users/'+this.userDetails.id;
    const endpoint1 = 'companies/add1';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    };

    /*
          let textAlertVal = 0;
          if(this.userDetails.textAlert == true)
            textAlertVal = 1;
          let emailAlertVal = 0
          if(this.userDetails.emailAlert == true)
            emailAlertVal = 1;*/
    console.log(this.userDetails.mobileAlerts,this.userDetails.emailAlerts);

    //this.newCompanyId = this.registerForm.get('company').value == 1 ? parseInt(this.lastCompanyNumber)+1 :  this.registerForm.get('company').value;
    //console.log("New company id "+ this.newCompanyId);
    //const credentials = this.registerForm.value;
    let params = {
      'id': this.userDetails.id,
      'username': this.userDetails.username,
      'password': '',
      'firstname': this.registerForm.get('firstname').value,
      'lastname': this.registerForm.get('lastname').value,
      'title': this.registerForm.get('title').value,
      'company': this.userDetails.company.id,
      'enabled': this.userDetails.enabled,
      'address1': this.userDetails.address1,
      'address2': this.userDetails.address2,
      'city': this.userDetails.city,
      'postalCode': this.userDetails.postalCode,
      'phone': this.userDetails.phone,
      'mobile': this.userDetails.mobile,
      'industryType': this.userDetails.industryType.id,
      'email': this.userDetails.email,
      'mobileAlerts':this.userDetails.mobileAlerts,
      'emailAlerts': this.userDetails.emailAlerts,
      'positionListAlert': this.userDetails.positionListAlert
    };

    console.log("PARAM: "+params);
    this.httpClient.put<RegisterResponse>(endpoint, params)
        .subscribe((response) => {
          if (response.status === 200) {
            alert("User details updated successfully !");
            this.mainRoute.navigate(['account']);
            //this.registerSuccess = true;
          }
        });
    this.isSubmitting = false;
  }

  passwordValidator(form: FormGroup) {
    const condition = form.get('password').value !== form.get('verifyPassword').value;
    return condition ? { passwordsDoNotMatch: true} : null;
  }

  newCompanyValidator(form:FormGroup) {
    const condition1 = form.get('company').value == 1 && form.get('newcompany').value.toString().trim().length == 0;
    return condition1 ? { enterNewCompany: true} : null;
  }
}

interface RegisterResponse {
  message: String;
  status: number;
  result: {
    token: string;
    username: string;
  };
}

interface FetchuserResponse {
  status: number;
  result: string;
}


export function validateEmail(httpClient: HttpClient, obj): AsyncValidatorFn {
  return (control: AbstractControl): Promise<ValidationErrors | null> | Observable<ValidationErrors | null> => {
    const endpoint = 'users/exists/' + control.value;
    return new Promise(function (resolve, reject) {
      if (control.value === obj.currentEmail) {
        resolve(null);
      }
      httpClient.get<FetchuserResponse>(endpoint)
          .subscribe((response) => {
            if (response.result !== '') {
              resolve({'userExists': true});
            } else {
              resolve(null);
            }
          });
    });
  };
}

/** Error when the parent is invalid */
class CrossFieldErrorMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    return control.dirty && form.invalid;
  }
}
