import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import { User } from '../user-detail/user';
import {Vessel} from '../vessel-detail/vessel';

@Component({
  selector: 'app-main-window',
  templateUrl: './main-window.component.html',
  styleUrls: ['./main-window.component.scss']
})
export class MainWindowComponent implements OnInit {

  users: User[] = [];
  vessels: Vessel[] = [];
  constructor(private  httpClient:  HttpClient) { }

  ngOnInit() {
    const endpoint = 'users';
    this.httpClient.get<GetUserResponse>(endpoint).subscribe((response) => {
      this.users = response.result;
      //console.log(this.users.id)
    });

    const endpoint1 = 'vessels';
    this.httpClient.get<Vesselresponse>(endpoint1).subscribe((response) => {
      this.vessels = response.result;
    });
  }

  private udpdateUseOutfirm(user) {

    if(user.useOutfirm == true)
      user.useOutfirm = 1
    else
      user.useOutfirm = 0
    const endpoint = 'users/update-outfirm';
    this.httpClient.post<any>(endpoint, user).subscribe((response) => {
      alert("User Use-OutFrom Information updated Successfully");
    });
  }
}


interface GetUserResponse {
  status: string;
  message: string;
  result: User[];
}

interface Vesselresponse {
  status: string;
  message: string;
  result: Vessel[];
}
