export class User {
  username: string;
  email: string;
  address1: string;
  address2: string;
  company: string;
  phone: string;
  industryType: string;
  firstname: string;
  lastname: string;
  enabled: any;
}
